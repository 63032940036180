import React, { createContext, useState, useEffect, useMemo } from 'react'
import firebase, { User } from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

import { docToDataObject, collectionToDataObjectAndArray, apiRequest } from 'helpers'
import { UserModel, AppVersions, Company } from 'interfaces'
import { settings } from 'config/settings'

interface AppContextData {
	user?: User
	userLoaded?: boolean
	userModel?: UserModel
	userModelLoaded?: boolean
	appVersions?: AppVersions
	appVersionsLoaded?: boolean
	employees?: { [key: string]: UserModel }
	employeeArray?: UserModel[]
	employeesLoaded?: boolean
	company?: Company
	companyLoaded?: boolean
	defaultTax?: number
	defaultTaxLoaded?: boolean
	secondaryFirebaseApp?: firebase.app.App
}
export const AppContext = createContext<AppContextData>({})

export const AppProvider: React.FC = (props) => {
	const [user, setUser] = useState<User>()
	const [userLoaded, setUserLoaded] = useState(false)
	const [userModel, setUserModel] = useState<UserModel>()
	const [userModelLoaded, setUserModelLoaded] = useState(false)
	const [appVersions, setAppVersions] = useState<AppVersions>()
	const [appVersionsLoaded, setAppVersionsLoaded] = useState(false)
	const [employees, setEmployees] = useState<{ [key: string]: UserModel }>()
	const [employeeArray, setEmployeeArray] = useState<UserModel[]>([])
	const [employeesLoaded, setEmployeesLoaded] = useState(false)
	const [company, setCompany] = useState<Company>()
	const [companyLoaded, setCompanyLoaded] = useState(false)
	const [defaultTax, setDefaultTax] = useState<number | undefined>(undefined)
	const [defaultTaxLoaded, setDefaultTaxLoaded] = useState(false)
	const [secondaryFirebaseApp, setSecondaryFirebaseApp] = useState<firebase.app.App | undefined>(
		undefined,
	)

	useEffect(() => {
		firebase.auth().onAuthStateChanged(
			(user) => {
				if (user) {
					setUser(user)
					setUserLoaded(true)

					const { uid } = user
					const data = { uid, last_sign_in: new Date().getTime() }
					apiRequest({ method: 'POST', path: 'update_user', data })
				} else {
					setUser(undefined)
					setUserLoaded(true)
				}
			},
			(e) => {
				console.error(e)
				setUser(undefined)
				setUserLoaded(true)
			},
			() => console.log('onAuthStateChanged Completed'),
		)
		const {
			FIREBASE_API_KEY,
			FIREBASE_AUTH_DOMAIN,
			FIREBASE_DATABASE_URL,
			FIREBASE_PROJECT_ID,
			FIREBASE_STORAGE_BUCKET,
			FIREBASE_MESSAGING_SENDER_ID,
		} = settings

		setSecondaryFirebaseApp(
			firebase.initializeApp(
				{
					apiKey: FIREBASE_API_KEY,
					authDomain: FIREBASE_AUTH_DOMAIN,
					databaseURL: FIREBASE_DATABASE_URL,
					projectId: FIREBASE_PROJECT_ID,
					storageBucket: FIREBASE_STORAGE_BUCKET,
					messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
				},
				'secondary',
			),
		)
	}, [])

	useEffect(() => {
		let userWatcher: () => void
		if (user && user.uid) {
			userWatcher = firebase
				.firestore()
				.collection('Users')
				.doc(user.uid)
				.onSnapshot(
					(snapshot) => {
						setUserModel(docToDataObject(snapshot))
						setUserModelLoaded(true)
					},
					(e) => {
						console.log(e)
						setUserModel(undefined)
						setUserModelLoaded(true)
					},
				)
		}

		return () => {
			if (userWatcher) {
				userWatcher()
			}
			setUserModel(undefined)
			setUserModelLoaded(false)
		}
	}, [user])

	useEffect(() => {
		if (userModel && !userModel.account_active) {
			console.log('USER LOGGIN OUT!!!')
			firebase.auth().signOut()
		}
	}, [userModel])

	//App Version
	useEffect(() => {
		firebase
			.firestore()
			.collection('Versions')
			.doc('web')
			.onSnapshot(
				(snapshot) => {
					setAppVersions(docToDataObject(snapshot))
					setAppVersionsLoaded(true)
				},
				(e) => {
					setAppVersions(undefined)
					setAppVersionsLoaded(true)
				},
			)

		return () => {
			setAppVersions(undefined)
			setAppVersionsLoaded(false)
		}
	}, [])

	//Load Employees
	const company_id = useMemo(() => userModel && userModel.company_id, [userModel])
	useEffect(() => {
		let employeesWatcher: () => void
		let companyWatcher: () => void
		let taxWatcher: () => void
	
		if (company_id) {
			// Fetch Employees
			employeesWatcher = firebase
				.firestore()
				.collection('Users')
				.where('user_type', '==', 'employee')
				.where('company_id', '==', company_id)
				.onSnapshot(
					(snapshot) => {
						const { object, array } = collectionToDataObjectAndArray(snapshot)
						setEmployees(object)
						setEmployeeArray(array)
						setEmployeesLoaded(true)
					},
					(e) => {
						console.error(e)
						setEmployees(undefined)
						setEmployeeArray([])
						setEmployeesLoaded(true)
					},
				)
	
			// Fetch Company Info
			companyWatcher = firebase
				.firestore()
				.doc(`Companies/${company_id}`)
				.onSnapshot(
					(snapshot) => {
						setCompany(docToDataObject(snapshot))
						setCompanyLoaded(true)
					},
					(e) => {
						console.error(e)
						setCompany(undefined)
						setCompanyLoaded(true)
					},
				)
	
			// Fetch Default Tax
			taxWatcher = firebase
				.firestore()
				.collection('GeneralSettings')
				.where('comp_id', '==', company_id)
				.where('key', '==', 'defaultContractTax')
				.onSnapshot(
					(snapshot) => {
						if (!snapshot.empty) {
							const taxData = snapshot.docs[0].data()
							setDefaultTax(taxData.value)
						} else {
							setDefaultTax(0)
						}
						setDefaultTaxLoaded(true)
					},
					(e) => {
						console.error(e)
						setDefaultTax(0)
						setDefaultTaxLoaded(true)
					},
				)
		}
	
		return () => {
			// Cleanup existing watchers
			if (employeesWatcher) employeesWatcher()
			setEmployees(undefined)
			setEmployeeArray([])
			setEmployeesLoaded(true)
	
			if (companyWatcher) companyWatcher()
			setCompany(undefined)
			setCompanyLoaded(false)
	
			if (taxWatcher) taxWatcher()
			setDefaultTax(undefined)
			setDefaultTaxLoaded(false)
		}
	}, [company_id])

	return (
		<AppContext.Provider
			value={{
				user,
				userLoaded,
				userModel,
				userModelLoaded,
				appVersions,
				appVersionsLoaded,
				employees,
				employeeArray,
				employeesLoaded,
				company,
				companyLoaded,
				defaultTax,
				defaultTaxLoaded,
				secondaryFirebaseApp,
			}}
		>
			{props.children}
		</AppContext.Provider>
	)
}
